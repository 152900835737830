<template>
  <div class="membre">
    <Membre/>

  </div>
</template>
<script>
// @ is an alias to /src

import Membre from '../components/Membre.vue'

export default {
  name: 'Membres',
  components: {
    Membre
  }
}
</script>