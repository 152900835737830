<template>
  <div>
    <hr />
    <center>
      <h1>{{ h1 }}</h1>
    </center>
    <hr />
    <section
      v-if="!spiner"
      style="margin-top: 0px"
      class="box1 container-lg box-center"
    >
      <div
        style="text-align: center"
        v-if="erreur"
        class="alert alert-danger"
        role="alert"
      >
        {{ msg }}
      </div>
      <div
        style="text-align: center"
        v-else-if="msg2 !== ''"
        class="alert alert-success"
        role="alert"
      >
        {{ msg2 }}
      </div>
      <form class="xform">
        <center class="mb-3">
          <div class="nom-photo">{{ photo.name }}</div>
          <div
            @click="$refs.profilePhoto.click()"
            style="height: 150px; width: 150px; cursor: pointer"
            class="preview"
          >
            <input
            style="display: none"
            ref="profilePhoto"
            accept="image/*"
            type="file"
            @change="attachImage"
          />
            <div v-if="photo.name" style="margin: 0px" class="im">
              <img
              id="previewImage"
                style="height: 100%; width: 100%"
                src=""
                ref="profilePhotoDisplay"
                class="w-100px image-fit"
              />
            </div>
            <div
              v-else
              @row-clicked="viewImage"
              striped
              hover
              :items="images"
              :fields="image_fields"
              class="im"
            >
              <img
                v-if="TabElement.lien_photo && importation"
                :src="photo"
                alt="Photo"
                width="150"
                height="150"
                srcset=""
              />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-camera-fill"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path
                  d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 
                0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 
                1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                />
              </svg>
            </div>
          </div>
        </center>
        <input
          style="display: none"
          ref="profilePhoto"
          accept="image/*"
          type="file"
          @change="attachImage"
        />
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault01">Matricule</label>
            <input
              v-model.trim="matricule"
              type="text"
              class="form-control"
              id="validationDefault01"
              placeholder="Matricule"
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Prénom</label>
            <input
              v-model="prenom"
              type="text"
              id="validationDefault02"
              class="form-control"
              placeholder="Prénom"
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault01">Nom</label>
            <input
              v-model="nom"
              type="text"
              class="form-control"
              id="validationDefault01"
              placeholder="Nom"
              required
            />
          </div>
        </div>

        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Date de naissance</label>
            <input
              v-model="naissance"
              type="date"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="inputState">Genre</label>
            <select id="genre" v-model="genre" required :class="`form-control ${border_genre}`">
              <option value="M" selected>Masculin</option>
              <option value="F">Féminin</option>
            </select>
            <span v-if="border_genre" class="validity"></span>
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">E-mail</label>
            <input
              v-model.trim="mail"
              type="email"
              class="form-control"
              placeholder="exemple@gmail.com"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Domaine</label>
            <input
              v-model="domaine"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="inputState">Profession</label>
            <select v-model="profession" class="form-control">
              <option value="Entrepreneur" selected>Entrepreneur</option>
              <option value="Etudiant" selected>Etudiant</option>
              <option value="Employé" selected>
                Employé
              </option>
              <option value="Sans emploi" selected>Sans emploi</option>
              <option value="Autres" selected>Autres</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Téléphone</label>
            <input
              v-model.trim="telephone"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Date d'adhésion</label>
            <input
              v-model="dateAdhesion"
              type="date"
              class="form-control"
              placeholder="Frequence"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="col-md mb-2">
            <label for="inputState"
              >Bureau
              <svg
                v-if="!refresh1"
                @click="rafraichirBureau()"
                style="color: blue; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-counterclockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                />
                <path
                  d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                />
              </svg>
              <div
                v-else
                class="spinner-border text-primary"
                style="width: 1.5rem; height: 1.5rem"
                role="status"
              >
                <span class="visually-hidden"></span>
              </div>
            </label>
            <select v-model="bureau" class="form-control">
              <option
                v-for="item in all_bu"
                :key="item.index"
                :value="item.nom"
              >
                {{ item.nom }}
              </option>
            </select>
          </div>
          <div class="col-md mb-2">
            <label for="inputState">Pays</label>
            <select
              v-model="pays"
              data-live-search="true"
              class="form-control selectpicker"
            >
              <option
                :selected="preselect(item)"
                :data-tokens="item"
                v-for="item in nations"
                :key="item.index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Ville</label>
            <input
              v-model="ville"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="inputState"
              >Localitée
              <svg
                v-if="!refresh"
                @click="rafraichir()"
                style="color: blue; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-counterclockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                />
                <path
                  d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                />
              </svg>
              <div
                v-else
                class="spinner-border text-primary"
                style="width: 1.5rem; height: 1.5rem"
                role="status"
              >
                <span class="visually-hidden"></span>
              </div>
            </label>
            <select v-model="localite" class="form-control">
              <option
                v-for="item in all_lo"
                :key="item.index"
                :value="item.nom"
              >
                {{ item.nom }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Adresse</label>
            <input
              v-model="adresse"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="inputState">Zone</label>
            <select v-model="zone" class="form-control">
              <option value="zone 1" selected>Zone 1</option>
              <option value="zone 2" selected>Zone 2</option>
              <option value="zone 3" selected>Zone 3</option>
              <option value="zone 4" selected>Zone 4</option>
              <option value="zone 5" selected>Zone 5</option>
              <option value="zone 6" selected>Zone 6</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Whatsapp</label>
            <input
              v-model.trim="whatsapp"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Facebook</label>
            <input
              v-model.trim="facebook"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Linkedin</label>
            <input
              v-model.trim="linkedin"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Skype</label>
            <input
              v-model.trim="skype"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="inputState">Catégorie</label>
            <select v-model="categorie" class="form-control">
              <option value="Personne physique" selected>
                Personne physique
              </option>
              <option
                @click="ActiverChamps()"
                value="Représentant de personne morale"
              >
                Représentant de personne morale
              </option>
            </select>
          </div>
          <fieldset
            v-if="categorie == 'Personne physique'"
            id="ActiverChampsD"
            class="col-md mb-2"
            disabled="disabled"
          >
            <div>
              <label for="inputState">Organisation</label>
              <select v-model="organisation" class="form-control">
                <option
                  v-for="item in reponx"
                  :key="item.index"
                  :value="item.id"
                >
                  {{ item.nom }}
                </option>
              </select>
            </div>
          </fieldset>
          <div v-else class="col-md mb-2">
            <label for="inputState">Organisation</label>
            <select v-model="organisation" class="form-control">
              <option v-for="item in reponx" :key="item.index" :value="item.id">
                {{ item.nom }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Fonction</label>
            <input
              v-model="fonction"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="inputState">Etat Cotisation</label>
            <select v-model="etat_cotisation" class="form-control">
              <option value="A jour" selected>A jour</option>
              <option value="Non à jour">Non à jour</option>
              <option value="En cours">En cours</option>
            </select>
          </div>
        </div>
        <div>
          <div  v-if="typeCompteUser== 'Super Admin'" class="custom-control custom-switch">
            <input
              type="checkbox"
              v-model="activation"
              class="custom-control-input"
              id="item"
            />
            <label class="custom-control-label" for="item">
              Compte: Activé|Désactivé
            </label>
          </div>
        </div>
        <button
          @click="envoyer()"
          class="btn float-right btn-primary"
          type="submit"
        >
          Enregistrer</button
        ><br /><br />
      </form>
    </section>
    <div v-else class="chargement">
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<style>
select:invalid+.validity:after {
  content: 'Le genre est obligatoire'; 
  color: red;
}
/*select:invalid#genre {
  border-color: red;
}*/
</style>
<script>
import axios from "axios";
import { tabElemen } from "./l_membre.vue";
import { listesPays } from "./dataNomPays";
//import moment from 'moment';
export default {
  name: "Membre",
  data: () => {
    return {
      country: "",
      region: "",
      selected: "",
      base64: "",
      temp: "",
      imag: "",
      activation: false,
      rpnx: "",
      nom: "",
      prenom: "",
      naissance: "",
      genre: "",
      domaine: "",
      photo: "https://www.rab-tech.com/dev_mode/js/api/",
      profession: "",
      dateAdhesion: "",
      categorie: "",
      fonction: "",
      bureau: "",
      matricule: "",
      ville: "",
      localite: "",
      zone: "",
      telephone: "",
      mail: "",
      whatsapp: "",
      facebook: "",
      linkedin: "",
      skype: "",
      type_compte: "",
      etat_cotisation: "",
      organisation: "",
      pays: "Guinee",
      adresse: "",
      fonctionOrganisation: "",
      date_enregistrement: "",
      TabElement: tabElemen,
      importation: false,
      h1: "Nouveau Membre",
      image64: "",
      reponx: [],
      all_lo: "",
      all_bu: "",
      msg: "",
      erreur: false,
      spiner: false,
      msg2: "",
      cacher: "",
      reponx_code: "",
      refresh: false,
      refresh1: false,
      nations: listesPays,
      typeAction: "Désactiver",
      typeCompteUser: "",
      border_genre: "",
    };
  },
  computed: {
    genreG(){
      return this.etatGenre();
    }
  },
  methods: {
    etatGenre(){
      if (this.genre) {
       return this.border_genre = "";
      }
    },
    makeGetRequest: async function () {
      const res = await axios.get(
        "https://www.rab-tech.com/dev_mode/js/api/get_all_organisations.php"
      );
      this.reponx = res.data.organisations;
      this.all_bureau();
      this.all_localite();
    },
    rafraichirBureau: async function () {
      this.refresh1 = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_bureaux.php")
        .then((response) => {
          this.all_bu = response.data.values;
          console.log(this.all_bu);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.refresh1 = false;
        });
    },
    rafraichir: async function () {
      this.refresh = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_localites.php")
        .then((response) => {
          this.all_lo = response.data.values;
          console.log(this.all_lo);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.refresh = false;
        });
    },
    ActiverChamps: function () {
      var champ = document.getElementById("champsD");
      champ.disabled = !champ.disabled;
    },
    envoyer: async function () {
      if (!this.genre) {
        this.border_genre= "border-1 border-danger"
      } else {
        this.spiner = true;
      const Membre = new FormData();
      const COMPTE = new FormData();

      if (this.importation) {
        Membre.set("id", this.TabElement.id);
      }
      Membre.set("nom", this.nom);
      Membre.set("prenom", this.prenom);
      Membre.set("date_naissance", this.naissance);
      Membre.set("genre", this.genre);
      Membre.set("domaine_d_intervention", this.domaine);
      Membre.set("image", this.base64);
      Membre.set("profession", this.profession);
      Membre.set("date_adhesion", this.dateAdhesion);
      Membre.set("categorie", this.categorie);
      Membre.set("fonction", this.fonction);
      Membre.set("bureau", this.bureau);
      Membre.set("matricule", this.matricule);
      Membre.set("ville", this.ville);
      Membre.set("localite", this.localite);
      Membre.set("zone", this.zone);
      Membre.set("telephone", this.telephone);
      Membre.set("mail", this.mail);
      Membre.set("adresse", this.adresse);
      Membre.set("etat_cotisation", this.etat_cotisation);
      Membre.set("whatsapp", this.whatsapp);
      Membre.set("facebook", this.facebook);
      Membre.set("linkedin", this.linkedin);
      Membre.set("skype", this.skype);
      Membre.set("pays", this.pays);
      Membre.set("type", this.type_compte);
      Membre.set("organisation", this.organisation);
      
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/save_membre.php",
          Membre
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
            this.erreur = true;
            this.spiner = false;
          }
          this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
          this.erreur = true;
          this.spiner = false;
        })
        .finally(() => {
          // alert(this.msg2)

          if (this.reponx_code == "201") {
            if (
              (this.importation &&
                this.TabElement.is_valide == "0" &&
                this.activation == true) ||
              (this.importation &&
                this.TabElement.is_valide == "1" &&
                this.activation == false)
            ) {
              if (this.importation) {
                COMPTE.set("id", this.TabElement.id);
              }
              axios
                .post(
                  "https://www.rab-tech.com/dev_mode/js/api/switch_validite_membre.php",
                  COMPTE
                )
                .then((response) => {
                  this.rpnx = response.data.message;
                  console.log(this.rpnx);
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
                  if (this.rpnx == "Une erreur s'est produite") {
                    alert(this.rpnx);
                  }
                });
            }

            this.spiner = false;
            this.msg2 = "Enregistrement réussit.";
            this.erreur = false;
            this.nom = "";
            this.prenom = "";
            this.naissance = "";
            this.genre = "";
            this.domaine = "";
            this.base64 = "";
            this.profession = "";
            this.dateAdhesion = "";
            this.categorie = "";
            this.fonction = "";
            this.bureau = "";
            this.matricule = "";
            this.ville = "";
            this.localite = "";
            this.zone = "";
            this.telephone = "";
            this.mail = "";
            this.adresse = "";
            this.etat_cotisation = "";
            this.whatsapp = "";
            this.facebook = "";
            this.linkedin = "";
            this.skype = "";
            this.pays = "";
            this.type_compte = "";
            this.$router.push({ path: "/Membre-liste/0" });
          } else {
            this.spiner = false;
            this.erreur = true;
            this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
          }
        });
        }
    },
    verifierModification: function () {
      let lien = document.URL;
      let lien2 = lien.split("/").pop();
      if (this.TabElement && lien2 == "1") {
        if (this.TabElement.is_valide == "0") {
          this.activation = false;
          //this.typeAction = "Désactiver"
        } else {
          this.activation = true;
          //this.typeAction = "Activer"
        }
        this.importation = true;
        if (this.TabElement.nom) {
          this.h1 = this.TabElement.nom;
        }
        if (this.TabElement.nom) {
          this.nom = this.TabElement.nom;
        }
        if (this.TabElement.prenom) {
          this.prenom = this.TabElement.prenom;
        }
        if (this.TabElement.date_naissance) {
          this.naissance = this.TabElement.date_naissance;
        }
        if (this.TabElement.genre) {
          this.genre = this.TabElement.genre;
        }
        if (this.TabElement.domaine_d_intervention) {
          this.domaine = this.TabElement.domaine_d_intervention;
        }
        if (this.TabElement.profession) {
          this.profession = this.TabElement.profession;
        }
        if (this.TabElement.date_adhesion) {
          //this.TabElement.date_adhesion=this.TabElement.date_adhesion.split(' ');
          // this.TabElement.date_adhesion=this.TabElement.date_adhesion[0]
          //this.dateAdhesion= moment(String(this.TabElement.date_adhesion)).format("YYYY-MM-DDThh:mm")
          this.dateAdhesion = this.TabElement.date_adhesion;
        }
        if (this.TabElement.lien_photo) {
          this.photo = this.photo + this.TabElement.lien_photo;
        }
        if (this.TabElement.adresse) {
          this.adresse = this.TabElement.adresse;
        }
        if (this.TabElement.etat_cotisation) {
          this.etat_cotisation = this.TabElement.etat_cotisation;
        }
        if (this.TabElement.categorie) {
          this.categorie = this.TabElement.categorie;
        }
        if (this.TabElement.fonction) {
          this.fonction = this.TabElement.fonction;
        }
        if (this.TabElement.bureau) {
          this.bureau = this.TabElement.bureau;
        }
        if (this.TabElement.matricule) {
          this.matricule = this.TabElement.matricule;
        }
        if (this.TabElement.ville) {
          this.ville = this.TabElement.ville;
        }
        if (this.TabElement.localite) {
          this.localite = this.TabElement.localite;
        }
        if (this.TabElement.zone) {
          this.zone = this.TabElement.zone;
        }
        if (this.TabElement.telephone) {
          this.telephone = this.TabElement.telephone;
        }
        if (this.TabElement.mail) {
          this.mail = this.TabElement.mail;
        }
        if (this.TabElement.whatsapp) {
          this.whatsapp = this.TabElement.whatsapp;
        }
        if (this.TabElement.facebook) {
          this.facebook = this.TabElement.facebook;
        }
        if (this.TabElement.linkedin) {
          this.linkedin = this.TabElement.linkedin;
        }
        if (this.TabElement.skype) {
          this.skype = this.TabElement.skype;
        }
        if (this.TabElement.pays) {
          this.pays = this.TabElement.pays;
        }
        if (this.TabElement.type) {
          this.type_compte = this.TabElement.type;
        }
        if (this.TabElement.id_organisation) {
          this.organisation = this.TabElement.id_organisation;
        }
      }
    },
    preselect(city) {
      if (city == "Benin") {
        this.selected = "selected";
      }
      return this.selected;
    },
    attachImage() {
      let vm = this;
      this.photo = this.$refs.profilePhoto.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.$refs.profilePhotoDisplay.src = reader.result;
        }.bind(this),
        false
      );
      reader.readAsDataURL(this.photo);
      reader.onload = function (e) {
        vm.image64 = e.target.result.split(",");
        vm.base64 = vm.image64[1];
      };
      return vm.base64;
    },
    all_bureau: async function () {
     // this.spiner = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_bureaux.php")
        .then((response) => {
          this.all_bu = response.data.values;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spiner = false;
        });
    },
    all_localite: async function () {
      //this.spiner = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_localites.php")
        .then((response) => {
          this.all_lo = response.data.values;
          console.log(this.all_lo);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spiner = false;
        });
    },
  },
  created() {
    this.verifierModification();
    this.makeGetRequest();
    let elementUser = JSON.parse(sessionStorage.getItem("authUser"));
    this.typeCompteUser = elementUser.type;
  },
};
</script>

